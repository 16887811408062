import React from 'react';
import '../ryanStyle/style.css';
import '../ryanStyle/more.css'
import img1 from '../img/pinkney.jpg';
import img2 from '../img/intex.jpeg';


function More(){

    return (

        <div class="wrapper">
            <div class="sidebar">
                <div class="sidebar-text d-flex flex-column h-100 justify-content-center text-center"><br></br>
                    <img class="mx-auto d-block w-75 bg-primary img-fluid rounded-circle mb-4 p-3" src={img1} alt="" />
                    <h1 class="font-weight-bold">Ryan Pinkney</h1>
                    <p class="mb-4">
                        I have always been passionate about technology because it is fast moving like me. 
                        I am constantly on the go, and I am quick learner. My focus 
                        is to use technology to solve life's problems, both big and small.
                    </p>
                    <div class="d-flex justify-content-center mb-5">
                        <a class="btn btn-outline-primary mr-2" href="https://twitter.com/ryan_pinkney"><i class="fab fa-twitter"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.facebook.com/ryan.pinkney.1/"><i class="fab fa-facebook-f"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.linkedin.com/in/ryanpinkney/"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-outline-primary mr-2" href="https://www.instagram.com/ryan_mr_pinkney/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <a href="https://linktr.ee/pinkney_mr" class="btn btn-lg btn-block btn-primary mt-auto">More Links</a>
                </div>
                <div class="sidebar-icon d-flex flex-column h-100 justify-content-center text-right">
                    <i class="fas fa-2x fa-angle-double-right text-primary"></i>
                </div>
            </div>
            <div class="content">
             
                <div class="container p-0">
                    <nav class="navbar navbar-expand-lg bg-secondary navbar-dark">
                        <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div class="navbar-nav m-auto">
                                <a href="/" class="nav-item nav-link">Home</a>
                                <a href="about" class="nav-item nav-link">About</a>
                                {/* <a href="port" class="nav-item nav-link">Portfolio</a> */}
                                <a href="more" class="nav-item nav-link active">More</a>
                                <a href="contact" class="nav-item nav-link">Contact Me</a>
                            </div>
                        </div>
                    </nav>
                </div>
         
                <div class="container py-5 px-2 bg-primary">
                <div class="row py-5 px-4">
                    <div class="col-sm-6 text-center text-md-left">
                        <h1 class="mb-3 mb-md-0 text-white text-uppercase font-weight-bold">More</h1>
                    </div>
                    <div class="col-sm-6 text-center text-md-right">
                        <div class="d-inline-flex pt-2">
                            <h4 class="m-0 text-white"><a class="text-white" href="/">Home</a></h4>
                            <h4 class="m-0 text-white px-2">/</h4>
                            <h4 class="m-0 text-white">More</h4>
                        </div>
                    </div>
                </div>
            </div>
                    <div class="container bg-white pt-5">
                            <div class="row px-3 pb-5">
                                <div class="col-md-12">

                        {/* <img class="w-100" className="ImageFormat" src={img2} alt="" /> */}
                        <br></br><br></br>                          
                                    <h2 class="mb-4 font-weight-bold">Certifications</h2>
                                    <ul>
                                        <li>Salesforce System Administrator</li>
                                        <li>Salesforce Platform App Builder</li>
                                        <li>Salesforce Agentforce Specialist</li>
                                        <li>Salesforce AI Associate</li>
                                        <li>AWS Cloud Essentials</li>
                                        <li>ACTFL Portuguese: Advanced Low</li>
                                        <li>FIFA Referee Certification</li>
                                        <li>NASE Scuba Certified</li>
                                    </ul><br></br>
                                    <h3 class="mb-4 font-weight-bold"><a href='https://linktr.ee/mr_pinkney_publications'>Publications:</a></h3>
                                    <ul>
                                        <li>Co-translator under supervision of Dr. Rex Nelson: Brazilian Anthology of Environmental Literature (publication pending) </li>
                                        <li>“Book Review: Extreme Ownership” - The Marriott Student Review</li>
                                        <li>“iGen: An Insight into Generational Trends and the Youth Of Today” - Medium</li>
                                        <li>“Digital Minimalism: Is Your Phone Controlling You?” - Medium</li>
                                        <li>“Is Caleb Hammer the Next Dave Ramsey?” - Medium</li>
                                        <li>“Honest Book Review: Moment of Lift by Melinda Gates” - Medium</li>
                                        <li>"Salesforce vs. ServiceNow: Choosing the Ultimate Enterprise Companion” - Medium</li>
                                        <li>“What is a platform application developer?” - Medium</li>
                                        <li>"What does a Salesforce system admin do?” - Medium</li>
                                        <li>“Design Thinking and Agile: The Truth” - Medium</li>
                                        <li>“Why Salesforce?” - Medium  </li>
                                        <li>“The Millionaire Next Door — Book Review” - Medium</li> 
                                        <li>“Ryan’s Book List for 2024” - Medium</li>
                                    </ul><br></br>
                                    <h3 class="mb-4 font-weight-bold">Other Achievements:</h3>
                                    <ul>
                                        <li>BYU Marriott School of Business Lewis M. Jones Scholarship Recipient</li>
                                        <li>BYU Marriott School of Business G. Rodger and Marilyn M. Victor Scholarship Recipient</li>
                                        <li>Department of Education FLAS Portugues Scholarship Recipient</li>
                                        <li>BYU Marriott School of Business Dean’s List Winter 2022</li>
                                        <li>BYU Information Systems Cherrington Scholar</li>
                                        <li>BYU Information Systems INTEX 3rd Place (Winter 2022)</li>
                                        <li>BSA Eagle Scout</li>
                                        <li>BSA Order of the Arrow</li>
                                        <li>America Legion Boy's State: Editor-in-Chief</li>
                                        <li>JMU Drum Major Camp: Participant</li>
                                    </ul>
                           <br></br>
                    </div><br></br>
                        <div class="container py-4 bg-secondary text-center">
                            <p class="m-0 text-white">
                                &copy; <a class="text-white font-weight-bold" href="index.html">Ryan Pinkney</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default More;